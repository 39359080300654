import "./App.css";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";
import Header from "components/templates/Header";
import AppModal from "components/templates/AppModal";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import RegisterAppDispatch from "components/atoms/RegisterAppDispatch";

import theme from "./theme";
import createCache from "@emotion/cache";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/lab";
import { LocalizationProvider as NewLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dateAdapter from "@mui/lab/AdapterDateFns";
// import { ScopedCssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLayoutEffect } from "react";
import Layout from "components/organisms/Layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useEffectOnce from "hooks/useEffectOnce";
import AuthService from "services/auth.service";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
const cache = createCache({ key: "css", prepend: true });
const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
export default function App() {
  useEffectOnce(() => {
    if (localStorage.getItem("token")) {
      AuthService.me();
    }
  });
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={5}
          hideIconVariant
          preventDuplicate
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          iconVariant={{
            success: "✅",
            error: "✖️",
            warning: "⚠️",
            info: "ℹ️",
          }}
        >
          <Toaster />
          <ErrorBoundary>
            <CacheProvider value={cache}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={dateAdapter}>
                  <CssBaseline />
                  <NewLocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-us"
                  >
                    <Provider store={store}>
                      <RegisterAppDispatch />
                      <BrowserRouter>
                        <Wrapper>
                          <Layout>
                            <ErrorBoundary>
                              <AppRoutes />
                            </ErrorBoundary>
                          </Layout>

                          <ErrorBoundary>
                            <AppModal />
                          </ErrorBoundary>
                        </Wrapper>
                      </BrowserRouter>
                    </Provider>
                  </NewLocalizationProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </CacheProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
