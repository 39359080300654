import { config } from "config";
import { SetCampaignsPayload, CampaignState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: CampaignState = {
  count: 0,
  campaigns: [],
  campaign: null,
  loading: true,
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  campaignOptions: [],
  options: [],
  filters: { page: 1, pageSize: default_page_size },
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    clear_search: (state) => {
      state.campaigns = [];
      state.campaignOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setOptions: (state, action: PayloadAction<any[]>) => {
      state.campaignOptions = action.payload;
    },
    updateCampaign: (state, action) => {
      const { id, campaign } = action.payload;
      state.campaigns.every(({ _id }, i) => {
        if (id === _id) {
          state.campaigns[i] = campaign;
          return false;
        }
        return true;
      });
    },
    deleteCampaignById: (state, action) => {
      const id = action.payload;
      state.campaigns.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.campaigns.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },

    addCampaign: (state, action) => {
      state.campaigns.unshift(action.payload);
    },

    setCampaigns: (state, action: PayloadAction<SetCampaignsPayload>) => {
      const { count, campaigns } = action.payload;
      state.count = count;
      state.refreshLoader = false;

      state.campaigns = campaigns;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const campaignReducer = campaignSlice.reducer;

export const campaignActions = campaignSlice.actions;
export default campaignReducer;
