import { config } from "config";
import { SetListsPayload, ListState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: ListState = {
  count: 0,
  lists: [],
  list: null,
  loading: true,
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  listOptions: [],
  options: [],
  filters: { page: 1, pageSize: default_page_size },
};

export const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    clear_search: (state) => {
      state.lists = [];
      state.listOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setOptions: (state, action: PayloadAction<any[]>) => {
      state.listOptions = action.payload;
    },
    updateList: (state, action) => {
      const { id, list } = action.payload;
      state.lists.every(({ _id }, i) => {
        if (id === _id) {
          state.lists[i] = list;
          return false;
        }
        return true;
      });
    },
    deleteListById: (state, action) => {
      const id = action.payload;
      state.lists.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.lists.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setList: (state, action) => {
      state.list = action.payload;
    },

    addList: (state, action) => {
      state.lists.unshift(action.payload);
    },

    setLists: (state, action: PayloadAction<SetListsPayload>) => {
      const { count, lists } = action.payload;
      state.count = count;
      state.refreshLoader = false;
      state.lists = lists;
    },
    setSearchLists: (state, action: PayloadAction<SetListsPayload>) => {
      const { count, lists } = action.payload;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      lists.forEach(({ _id, fullName, email }: any) =>
        options.push({ value: _id, label: fullName + " " + " - " + email })
      );
      state.lists = lists;
      state.listOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const listReducer = listSlice.reducer;

export const listActions = listSlice.actions;
export default listReducer;
