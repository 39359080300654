import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SelectRedux from "components/molecules/SelectRedux";
import CampaignService from "services/campaign.service";
import { campaignActions } from "redux/slices/campaign";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";

export default function SelectCampaigns(props: any) {
  const dispatch = useAppDispatch();
  const form = props?.meta.form;
  const campaignOptions = useAppSelector(
    (state) => state.campaign?.campaignOptions
  );
  const company = useAppSelector(
    (state) => state.form?.["AddLisRequestForm"]?.values
  )?.company;
  useEffect(() => {
    if (form == "AddLisRequestForm") {
      if (company)
        CampaignService.getCampaignsOption({ company: company?.value });
    } else {
      CampaignService.getCampaignsOption();
    }
    return () => {
      dispatch?.(campaignActions.setOptions([]));
    };
  }, [form, company, dispatch]);

  return form == "AddLisRequestForm" ? (
    <ComboBoxRedux {...props} options={campaignOptions} />
  ) : (
    <SelectRedux {...props} options={campaignOptions} />
  );
}
