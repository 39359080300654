import DatePickerRedux from "components/molecules/DatePickerRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectCampaigns from "components/organisms/SelectCampaign";
import { required } from "utils/validate.util";

export { default } from "./AddListForm";

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Name",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 12 },
  },
  {
    name: "campaign",
    label: "Campaign",
    component: SelectCampaigns,
    cellProps: { md: 12 },
  },
  // {
  //   name: "startDate",
  //   label: "Start Date",
  //   component: DatePickerRedux,
  //   cellProps: { md: 12 },
  // },
  {
    name: "notes",
    label: "Notes",
    component: InputRedux,
    InputProps: { multiline: true, rows: 4 },
    cellProps: { md: 12 },
  },
];
