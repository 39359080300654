import ToasterService from "utils/toaster.util";
import http from "./http.service";
import Promisable from "./promisable.service";
import axios from "axios";
import { getAppDispatch } from "utils/dispatch.util";
import { loaderActions } from "redux/slices/loader";

const ImageService = {
  getImageFileFromBlob: async ({ blob, name, type }: any) => {
    const response = await fetch(blob);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get("content-type") || type,
    });
  },

  uploadFile: async (data: any, folder?: any) => {
    let img = await ImageService.getImageFileFromBlob(data);
    http.setJWT();
    http.setMultiPart();
    let formData = new FormData();
    formData.append("file", img);
    const [success, error]: any = await Promisable.asPromise(
      http.post(`/file/upload?folder=${folder}`, formData)
    );
    if (success && success?.data?.data?.url) {
      return success?.data?.data?.url[0].path;
    } else ToasterService.showError(`${error?.errorMessage}`);
  },

  getIcons: async (type?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/file/icons`)
    );
    if (success && success?.data?.data?.icons) {
      let icons = success?.data?.data?.icons?.filter(
        (item: any) => item.type === type
      );
      return icons?.map((item: any) => item.key);
    } else ToasterService.showError(`${error?.errorMessage}`);
  },
  getSignedUrl: async (file?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/generate-presigned-url?filename=${file.name}`)
    );
    if (success && success?.data) {
      return success?.data;
    } else ToasterService.showError(`${error?.errorMessage}`);
  },
  getUploadTime: async (file?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/get_upload_time/${file}`)
    );
    if (success && success?.data) {
      return success?.data;
    } else ToasterService.showError(`${error?.errorMessage}`);
  },
  deleteFile: async (file?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.delete(`/${file}`)
    );
    ToasterService.showSuccess(`File has been removed`);
  },
  uploadToSignedUrl: async (url: any, file: any) => {
    const dispatch = getAppDispatch();
    const s3Axios = axios.create();
    dispatch?.(loaderActions.setImgLoading(true));

    s3Axios.defaults.baseURL = "";
    delete s3Axios.defaults.headers.common["Authorization"];

    try {
      await s3Axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const percentComplete =
              (progressEvent.loaded / progressEvent.total) * 100;
            dispatch?.(loaderActions.setProgress(percentComplete));
            if (percentComplete == 100) {
              dispatch?.(loaderActions.setImgLoading(false));
              dispatch?.(loaderActions.setProgress(0));
            }
            console.log("percentComplete", percentComplete);
          }
        },
      });
    } catch (error) {}
  },
};

export default ImageService;
