import { getAppDispatch } from "utils/dispatch.util";
import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";

const url = "/videoView";

const VideoViewService = {
  create: async (data: any) => {
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );
    return [success, error];
  },

  getAll: async (file: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${file}`)
    );
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  addVideoReview: async (file: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(modalActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addReview/${file}`, data)
    );
    if (success) {
      const { creator } = success?.data?.data || {};

      dispatch?.(modalActions.closeModal(creator));
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
};
export default VideoViewService;
