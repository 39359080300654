import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import InitializationService from "services/initialization.service";
import { GoogleOAuthProvider } from "@react-oauth/google";

InitializationService.init();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId="672624705082-igetqsmq90m4s5klcudt4rfasgdti4sv.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
