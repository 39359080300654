import { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import SelectRedux from "components/molecules/SelectRedux";
import CompanyService from "services/company.service";
import ComboBoxRedux from "../ComboBoxRedux";

export default function SelectCompany(props: any) {
  const form = props?.meta.form;
  const companyOptions = useAppSelector(
    (state) => state.company?.companyOptions
  );

  useEffect(() => {
    CompanyService.getAllCompanies();
  }, []);

  return form == "AddLisRequestForm" ? (
    <ComboBoxRedux {...props} options={companyOptions} />
  ) : (
    <SelectRedux {...props} options={companyOptions} />
  );
}
