import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { change, reduxForm } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { fields } from ".";
import UpdateCampaignForm from "../UpdateCampaignForm";
import SelectCompany from "components/molecules/SelectCompany";
import { useEffect, useState } from "react";
import { required } from "utils/validate.util";

function AddCampaignForm({ form, handleSubmit }: any) {
  const dispatch = useAppDispatch();
  const { _id, company_id } = useAppSelector((state) => state.modal.data);
  const [field, setFields] = useState(fields);
  const user = useAppSelector((state) => state.auth.user);
  useEffect(() => {
    if (user.role !== "user") {
      setFields([
        ...fields,
        {
          name: "company",
          label: "Company",
          validate: [required],
          component: SelectCompany,
          cellProps: { sm: 12 },
        },
      ]);
      if (company_id) dispatch(change(form, "company", company_id));
    }
  }, [user, company_id]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={field} />
        <br />
        <br />
        <Button
          variant="outlined"
          onClick={() => dispatch(modalActions.closeModal())}
          sx={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
        {_id && <UpdateCampaignForm />}
      </form>
    </div>
  );
}

export default reduxForm({ form: "AddCampaignForm" })(AddCampaignForm);
