import CircleLoader from "components/atoms/CircleLoader";
import AddListForm from "components/templates/AppModal/AddList/AddListForm";
import { useEffect, useState } from "react";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ListService from "services/list.service";

export default function AddList() {
  const { _id, campaign_id, company_id } = useAppSelector(
    (state) => state.modal.data
  );
  const loading = useAppSelector((state) => state.modal.loading);
  const dispatch = useAppDispatch();
  const handleSubmit = async (values: any) => {
    let data = { ...values };
    if (_id) {
      ListService.updateList(_id, {
        ...data,
        startDate: data.startDate?.date,
      });
    } else {
      ListService.addList(
        {
          ...data,
          startDate: data.startDate?.date,
        },
        campaign_id
      );
    }
  };

  useEffect(() => {
    if (campaign_id) dispatch(change("AddListForm", "campaign", campaign_id));
    if (company_id) dispatch(change("AddListForm", "company", company_id));
  }, [campaign_id, company_id, dispatch]);

  return (
    <div>
      <h3>{_id ? "Update" : "Add"} List</h3>
      {loading && <CircleLoader />}

      <AddListForm onSubmit={handleSubmit} />
    </div>
  );
}
