import { useAppSelector } from "redux/hooks";

import { config } from "config";
import { useEffect, useState } from "react";
import VideoViewService from "services/videoView.service";
import moment from "moment";
import { NoResultCard } from "components/templates/NoResult/NoResult";

export default function Views() {
  const { file, date } = useAppSelector((state) => state.modal.data);
  const loading = useAppSelector((state) => state.modal.loading);

  const [list, setList] = useState([]);
  useEffect(() => {
    if (file) {
      VideoViewService.getAll(file).then(([data]) => {
        if (data?.data?.data?.views) {
          setList(data?.data?.data?.views);
        }
      });
    }
    return () => {
      setList([]);
    };
  }, [file]);

  return (
    <div>
      <h3>Uploaded At: {moment(date).calendar()}</h3>
      {list?.length > 0 ? (
        <table
          style={{
            paddingTop: "25px",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                User
              </th>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                View At
              </th>{" "}
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Action
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Notes
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item: any, index: number) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {item.user?.fullName}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {moment(item.createAt).calendar()}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {item.action}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {item.notes}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && (
          <div>
            <NoResultCard height={"30vh"} />
          </div>
        )
      )}
    </div>
  );
}
