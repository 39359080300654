import { useEffect } from "react";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateListForm() {
  const form = "AddListForm";
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.modal.data);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(change(form, "name", data?.name));
    dispatch(change(form, "notes", data?.notes));
    dispatch(change(form, "campaign", data?.campaign?._id));
    if (user?.role !== "user")
      dispatch(change(form, "company", data?.company._id || data?.company));

    dispatch(
      change(form, "startDate", {
        date: new Date(data.startDate),
        error: false,
      })
    );
  }, [data, user]);

  return null;
}
