import { formLoaderActions } from "redux/slices/formLoader";
import http from "./http.service";
import Promisable from "./promisable.service";
import { authActions } from "redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";

const url = "/auth";

const AuthService = {
  login: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/login`, data)
    );

    if (success) {
      console.log("success", success);
      const { user, jwtToken } = success?.data?.data || {};
      localStorage.setItem("token", `Bearer ${jwtToken}`);
      dispatch?.(authActions.setUser(user));
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  me: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/me`)
    );

    if (success) {
      console.log("success", success);
      const { user } = success?.data?.data || {};
      dispatch?.(authActions.setUser(user));
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  update: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update`, data)
    );

    if (success) {
      const { user } = success?.data?.data || {};
      dispatch?.(authActions.setUser(user));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  logout: () => {
    const dispatch = getAppDispatch();

    localStorage.removeItem("token");

    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
    window.location.href = "/";
  },
};
export default AuthService;
