import Empty from "assets/notfound.png";
import { TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../Tables";

export default function NoResult({ message, height }: any) {
  return (
    <TableBody style={{ width: "100%" }}>
      <StyledTableRow>
        <StyledTableCell align="center">
          <div
            style={{
              minHeight: height || "80vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Empty}
              loading="lazy"
              alt="No results"
              style={{ width: "150px" }}
            />
            <br />
            <h3>Sorry! No results found :&#40;</h3>
            {/* <p style={{ margin: 0 }}>{message}.</p> */}
          </div>
        </StyledTableCell>
      </StyledTableRow>
    </TableBody>
  );
}

export function NoResultCard({ message, height }: any) {
  return (
    <div
      style={{
        minHeight: height || "60vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={Empty}
        loading="lazy"
        alt="No results"
        style={{ width: "150px" }}
      />
      <br />
      <h3>Sorry! No results found :&#40;</h3>
      <p style={{ margin: 0 }}>{message}.</p>
    </div>
  );
}
