import Input from "../Input";
import { FileUploadOwnProps } from ".";
import ToasterService from "utils/toaster.util";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useEffect, useState } from "react";
import { config } from "config";
import CircleLoader from "../CircleLoader";
import { useAppSelector } from "redux/hooks";
import CircularProgress from "../CircularProgress";
import ProgressLoader from "../ProgressLoader";
import { MdOutlineDelete } from "react-icons/md";
import ImageService from "services/image.service";
export type FileUploadProps = FileUploadOwnProps &
  React.ComponentProps<typeof Input>;

const isVideoUrl = (url: any) => {
  const videoExtensions = [
    "mp4",
    "webm",
    "ogv",
    "avi",
    "mov",
    "flv",
    "mpeg",
    "mpg",
    "mkv",
    "3gp",
    "ts",
  ];
  const extension = url.split(".").pop().toLowerCase();
  return videoExtensions.includes(extension);
};
const MediaDisplay = ({ url }: any) => {
  if (isVideoUrl(url)) {
    return (
      <video controls style={{ width: "100%", height: "100%" }}>
        <source src={url} type="video/mp4" />
      </video>
    );
  } else {
    return (
      <img src={url} alt="media" style={{ maxWidth: "100%", height: "auto" }} />
    );
  }
};
export default function FileUpload({
  value,
  accept,
  maxSize,
  onChange,
  sx,
  uploadIcon = true,
  ...rest
}: FileUploadProps & { onChange: any; rest?: any }) {
  const [Preview, serPreview] = useState<any>(null);

  useEffect(() => {
    if (value && typeof value === "string") {
      serPreview(`https://dev-imm.s3.amazonaws.com/${value}`);
    } else if (rest.defaultImage) {
      serPreview(rest.defaultImage);
    }
  }, [value]);

  let setNull = rest?.setNull;
  const loading = useAppSelector((state) => state.loader.imgLoading);
  const progress = useAppSelector((state) => state.loader.progress);
  let isLoading = !loading;

  useEffect(() => {
    if (isLoading && Preview && setNull) {
      serPreview(null);
    }
  }, [loading, Preview, setNull]);
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {rest.name !== "profileImage" && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px",
            background: "#f7f7f7",
            top: "5px",
            right: "5px",
            flexDirection: "column",
            boxShadow: "0px 0px 3px 0px hsl(0deg 0% 13% / 45%)",
            borderRadius: "50%",
            cursor: "pointer",

            zIndex: 99,
          }}
          onClick={() => {
            if (value && typeof value === "string") {
              ImageService.deleteFile(value);
              onChange("");
            }
            serPreview(null);
          }}
        >
          <MdOutlineDelete size={24} />
        </Box>
      )}
      <InputLabel
        htmlFor={rest.id}
        sx={{
          cursor: "pointer",
          width: rest.width || "100%",
          height: rest.height || "273px",
          background: "#fafafa",
          borderRadius: "4px",
          position: "relative",
          ...sx,
        }}
      >
        {/* {uploadIcon && (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px",
              background: "hsl(0deg 0% 13% / 6%)",
              top: "5px",
              right: "5px",
              flexDirection: "column",
              boxShadow: "0px 0px 3px 0px hsl(0deg 0% 13% / 45%)",
              borderRadius: "50%",
              zIndex: 99,
            }}
          >
            <MdOutlineDelete size={24} />
          </Box>
        )} */}

        {Preview && Preview?.includes("https://dev-imm.s3.amazonaws.com/") ? (
          <MediaDisplay url={Preview} />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              fontWeight: 500,
              flexDirection: "column",
            }}
          >
            <div>{rest.title || "Upload Logo"}</div>
            {loading && <ProgressLoader progress={progress} />}
          </Box>
        )}

        <div hidden>
          <Input
            {...rest}
            type="file"
            id={rest.id}
            hidden
            inputProps={{ accept, ...rest.inputProps }}
            InputLabelProps={{ shrink: true, ...rest.InputLabelProps }}
            onChange={(e: any) => {
              let file = e.target.files?.[0];
              if (!file) return onChange?.(file, rest.name);
              if (accept) {
                let types = file.name.split(".");
                let type = types[types.length - 1].toLowerCase();
                if (!accept.includes(`.${type}`)) {
                  ToasterService.showError(`${type} not allowed!`);
                  let error: any = { error: `${type} not allowed!` };
                  return onChange?.(error);
                }
              }
              if (maxSize) {
                let size = maxSize * 1024 * 1024;
                if (file.size > size) {
                  ToasterService.showError(`Max file size is ${maxSize} MB`);
                  let error: any = { error: `Max file size is ${maxSize} MB` };
                  return onChange?.(error);
                }
              }
              serPreview(URL.createObjectURL(file));
              onChange?.(file);
            }}
          />
        </div>
      </InputLabel>
    </Box>
  );
}
