import { Suspense, useEffect, useState } from "react";
import PrivateRoute from "./PrivateRoute";
import {
  public_routes,
  private_routes,
  IRoute,
  client_routes,
  scraper_routes,
} from "routes";
import CircleLoader from "components/atoms/CircleLoader";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import useEffectOnce from "hooks/useEffectOnce";

export default function AppRoutes() {
  const user = useAppSelector((state) => state.auth?.user);
  const [routesAccess, setRoutesAccess] = useState<IRoute[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  // useEffectOnce(() => {
  //   // private_routes
  //   if (user) {
  //     let private_routesCheck = [];
  //     if (user.role === "superAdmin" || user.role === "admin")
  //       private_routesCheck.push(...private_routes);
  //     // if (user.role === "admin") private_routesCheck.push(...private_routes);
  //     private_routesCheck.push(...client_routes);

  //     setRoutesAccess(private_routesCheck);
  //     setLoading(false);
  //   } else {
  //     AuthService.logout();
  //     setLoading(false);
  //   }
  // });
  return (
    <div style={{ minHeight: "90vh", position: "relative" }}>
      {loading ? (
        <CircleLoader />
      ) : (
        <Suspense fallback={<CircleLoader />}>
          <Routes>
            {public_routes.map(({ path, element }, i) => (
              <Route key={i} path={path} element={element} />
            ))}

            {user && (
              <>
                {["superAdmin", "admin"].includes(user?.role) &&
                  private_routes.map(({ path, element }, i) => (
                    <Route
                      key={i}
                      path={path}
                      element={<PrivateRoute>{element}</PrivateRoute>}
                    />
                  ))}

                {["superAdmin", "admin", "user"].includes(user?.role) &&
                  client_routes.map(({ path, element }, i) => (
                    <Route
                      key={i}
                      path={path}
                      element={<PrivateRoute>{element}</PrivateRoute>}
                    />
                  ))}
                {["scraper"].includes(user?.role) &&
                  scraper_routes.map(({ path, element }, i) => (
                    <Route
                      key={i}
                      path={path}
                      element={<PrivateRoute>{element}</PrivateRoute>}
                    />
                  ))}
              </>
            )}

            <Route path="*" element={<Navigate to="/" />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Suspense>
      )}
    </div>
  );
}
