import http from "./http.service";
import { reset } from "redux-form";
import Promisable from "./promisable.service";
import { campaignActions } from "redux/slices/campaign";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import ObjectService from "utils/object.util";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import ToasterService from "utils/toaster.util";
import DateService from "utils/date.util";
import CompanyService from "./company.service";

const url = "/campaign";

const CampaignService = {
  getCampaign: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { campaign } = success?.data?.data || {};
      dispatch?.(campaignActions.setCampaign(campaign));
      dispatch?.(campaignActions.setLoading(false));
    } else dispatch?.(campaignActions.setCampaign({ data: "Not Found" }));

    dispatch?.(campaignActions.setLoading(false));
    return [success, error];
  },
  getSearchCampaigns: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { campaigns } = success?.data?.data || {};
      const count = campaigns.length || 0;
      dispatch?.(campaignActions.setLoading(false));
    } else dispatch?.(campaignActions.setCampaign({ data: "Not Found" }));

    dispatch?.(campaignActions.setLoading(false));
    return [success, error];
  },

  getCampaigns: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { campaigns, count } = success?.data?.data || {};
      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: campaigns.map((campaign: any) => ({
              Name: campaign.name,
              Notes: campaign.notes,
              "Start Date": DateService.getServerDateString(campaign.startDate),
            })),
          })
        );
      else dispatch?.(campaignActions.setCampaigns({ campaigns, count }));
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(campaignActions.setLoading(false));
    return [success, error];
  },
  getCampaignsOption: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getOption`, data)
    );

    if (success) {
      const { campaigns, totalCount } = success?.data?.data || {};
      dispatch?.(campaignActions.setOptions(campaigns));
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(campaignActions.setLoading(false));
    return [success, error];
  },
  getAll: async (data?: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/select`, data)
    );

    if (success) {
      const { campaigns } = success?.data?.data || {};
      if (campaigns?.length > 0) {
        let list = campaigns?.map((item: any) => ({
          value: item._id,
          label: item.fullName,
        }));
        dispatch?.(campaignActions.setOptions(list));
      }
    }
    dispatch?.(campaignActions.setLoading(false));
    return [];
  },
  addCampaign: async (data: any, company?: any, noClose?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );

    if (success) {
      const { campaign } = success?.data?.data || {};
      dispatch?.(campaignActions.addCampaign(campaign));
      if (company) CompanyService.getCompany(company);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    dispatch?.(modalActions.setLoading(false));
    if (!noClose) dispatch?.(modalActions.closeModal());

    return [success, error];
  },

  updateCampaign: async (id: string, data: any, company?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );
    if (success) {
      const { campaign } = success?.data?.data || {};
      dispatch?.(campaignActions.updateCampaign({ id, campaign }));
      dispatch?.(modalActions.closeModal());
      if (company) CompanyService.getCompany(company);
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },

  deleteCampaign: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(campaignActions.deleteCampaignById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CampaignService;
