import moment from "moment";

const DateService = {
  getDurationMinutes: (value: number = 0) => {
    return value > 0
      ? moment.duration(value, "minutes").humanize()
      : "0 seconds";
  },

  getDurationString: (seconds: number = 0) => {
    let hours = seconds / 3600;
    let mins = (seconds % 3600) / 60;
    let secs = (mins * 60) % 60;

    hours = Math.trunc(hours);
    mins = Math.trunc(mins);

    if (!hours && !mins && !secs) return "0 sec";

    if (hours) {
      if (mins)
        return secs
          ? `${hours} hr ${mins} min & ${secs} sec`
          : `${hours} hr & ${mins} min`;
      else return secs ? `${hours} hr & ${secs} sec` : `${hours} hr`;
    } else {
      if (mins) return secs ? `${mins} min & ${secs} sec` : `${mins} min`;
      else return secs ? `${secs} sec` : `None`;
    }
  },

  getMonthString: (value: string = "") => {
    return moment(new Date(value)).format("MMMM-YYYY");
  },

  getDateRange: (value: any) => {
    return [
      value[0] ? new Date(value[0]).toString() : "",
      value[1] ? new Date(value[1]).toString() : "",
    ];
  },

  getShortDateString: (value: any) => {
    return value ? moment(value.replace("Z", "")).format("ddd, MMM DD") : "";
  },

  getDateString: (value: any, format = "ddd, MMM DD, YYYY") => {
    return value ? moment(value.replace("Z", "")).format(format) : "";
  },

  getTimeString12: (value: any) => {
    return value ? moment(value.replace("Z", "")).format("LT") : "";
  },
  getTimeString24: (value: any) => {
    return value ? moment(value).format("HH:mm") : "";
  },
  getTimeString: (value: any) => {
    return value ? moment(value.replace("Z", "")).format("HH:mm") : "";
  },
  getDateTimeString: (value: any) => {
    return value
      ? moment(value.replace("Z", "")).format("ddd, MMM DD, YYYY, HH:mm")
      : "";
  },
  getTimeString12To24: (value: any) => {
    return value ? moment(value, "HH:mm").format("h:mm A") : "";
  },
  getFormattedDate: (value: any) => {
    return value
      ? new Date(value).toLocaleDateString(undefined, {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "";
  },
  getNumericDate: (value: any) => {
    return value
      ? new Date(value).toLocaleDateString("fr-CH", {
          // weekday: "numeric",
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })
      : "";
  },

  getFormattedDateTime: (value: any) => {
    return value
      ? new Date(value).toLocaleDateString(undefined, {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        })
      : "";
  },
  getFormattedTime: (value: any) => {
    return value
      ? new Date(value).toLocaleDateString(undefined, {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        })
      : "";
  },

  getTimeOnly: (value: any) => {
    let valArr = value?.split(":");

    return value ? valArr[0] + ":" + valArr[1] : "";
  },
  getTimeToDate: (value: any) => {
    const [hours, minutes] = value.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toISOString();
  },

  // const [hours, minutes] = value.split(":");
  //   return `2023-12-14T${hours}:${minutes}:00.00Z`
  getDateOnly: (value: any) => {
    const initialMoment = moment(value).format().split("T")[0];

    return initialMoment.concat("T00:00:00.000+00:00");
  },
  getMomentDateFormat: (value: any) => {
    return value ? moment(value).format("DD.MM.YYYY") : "--";
  },
  getMomentTimeFormat: (value: any) => {
    return value ? moment(value).format("HH:mm") : "--";
  },
  getServerDateString: (value: any) => {
    let date = new Date(Date.parse(value));
    return date ? moment(date).format("MM/DD/YYYY") : "";
  },
  getReviewDate: (value: any) => {
    let date = new Date(Date.parse(value));
    return date ? moment(date).fromNow() : "";
  },
  getServerTimeString: (value: any) => {
    let date = new Date(Date.parse(value));
    return date ? moment(date).format("hh:mm A") : "";
  },
  mailDate: (dateString: string) => {
    const date = moment(dateString); // Parse the ISO date string with moment

    // Define the format for different cases
    if (date.isSame(moment(), "day")) {
      return `Today at ${date.format("h:mm A")}`;
    } else if (date.isSame(moment().subtract(1, "day"), "day")) {
      return `Yesterday at ${date.format("h:mm A")}`;
    } else if (date.isSameOrAfter(moment().subtract(7, "days"))) {
      return date.fromNow(); // e.g., "3 days ago"
    } else {
      return date.format("MMM D, YYYY"); // e.g., "Aug 25, 2024"
    }
  },
};

export default DateService;
