export const config = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.REACT_APP_ENV,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
  API_URL: process.env.REACT_APP_API_URL,
  // API_URL: process.env.REACT_APP_LOCAL_API_URL, // local
  APP_URL: `${process.env.REACT_APP_URL}`,
  LOCAL_API_URL: process.env.REACT_APP_LOCAL_API_URL,

  PAGE_SIZE: Number(process.env.REACT_APP_PAGE_SIZE) || 0,
  BUCKET_URL: process.env.REACT_APP_BUCKET_URL,
};
