export { default } from "./Layout";
interface LinkItem {
  title: string;
  link: string;
}

export const links = (user: any): LinkItem[] => {
  const list: LinkItem[] = [
    {
      title: "Campaigns",
      link: "/campaign",
    },
    {
      title: "Lists",
      link: "/list",
    },
    {
      title: "Creators",
      link: "/creator",
    },
  ];
  if (user && ["superAdmin", "admin"].includes(user?.role)) {
    list.unshift({
      title: "Companies",
      link: "/company",
    });
  }
  if (user && ["superAdmin", "admin"].includes(user?.role)) {
    list.push({
      title: "List Requests",
      link: "/list-requests",
    });
  }
  list.push({
    title: "Mailbox",
    link: "/mails",
  });
  if (user && ["superAdmin", "admin"].includes(user?.role)) {
    list.push({
      title: "Users",
      link: "/user",
    });
  }
  if (user && ["scraper"].includes(user?.role)) {
    return [
      {
        title: "List Requests",
        link: "/list-requests",
      },
    ];
  }

  return list;
};
