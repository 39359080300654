import { useEffect } from "react";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateCampaignForm() {
  const form = "AddCampaignForm";
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.modal.data);
  const user = useAppSelector((state) => state.auth.user);
  useEffect(() => {
    dispatch(change(form, "name", data?.name));
    dispatch(change(form, "notes", data?.notes));

    dispatch(
      change(form, "startDate", {
        date: new Date(data.startDate),
        error: false,
      })
    );
  }, [data, dispatch]);

  useEffect(() => {
    console.log("user", data?.company._id || data?.company);

    if (user?.role !== "user" && data) {
      dispatch(change(form, "company", data?.company._id || data?.company));
    }
  }, [user, data, dispatch]);

  return null;
}
