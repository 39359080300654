import CircleLoader from "components/atoms/CircleLoader";
import AddCampaignForm from "components/templates/AppModal/AddCampaign/AddCampaignForm";
import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import CampaignService from "services/campaign.service";

export default function AddCampaign() {
  const { _id, company_id } = useAppSelector((state) => state.modal.data);
  const loading = useAppSelector((state) => state.modal.loading);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    if (_id) {
      CampaignService.updateCampaign(
        _id,
        {
          ...data,
          startDate: data.startDate?.date,
        },
        company_id
      );
    } else {
      CampaignService.addCampaign(
        {
          ...data,
          startDate: data.startDate?.date,
        },
        company_id
      );
    }
  };
  return (
    <div>
      <h3>{_id ? "Update" : "Add"} Campaign</h3>
      {loading && <CircleLoader />}
      <AddCampaignForm onSubmit={handleSubmit} />
    </div>
  );
}
