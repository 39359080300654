export { default } from "./FileUpload";
export interface FileUploadOwnProps {
  maxSize?: number;
  accept?: string[];
  width?: string;
  height?: string;
  setNull?: boolean;
  sx?: any;
  defaultImage?: any;
  uploadIcon?: boolean;
}

const videoFileType = [
  ".mp4",
  ".webm",
  ".ogv",
  ".avi",
  ".mov",
  ".flv",
  ".mpeg",
  ".mpg",
  ".mkv",
  ".3gp",
  ".ts",
];
