import Input from "../Input";
import { ComboBoxOwnProps } from ".";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "../CircleLoader";
import CompanyService from "services/company.service";
import { modalActions } from "redux/slices/modal";
import { change } from "redux-form";
import Button from "../Button";
import ToasterService from "utils/toaster.util";
import CampaignService from "services/campaign.service";
import ListService from "services/list.service";
const filter = createFilterOptions<any>();

export type ComboBoxProps = ComboBoxOwnProps &
  Omit<React.ComponentProps<typeof Autocomplete>, "renderInput">;

export default function ComboBox({
  options = [],
  ChipProps,
  InputProps,
  ...rest
}: ComboBoxProps) {
  const { name, form } = InputProps as any;

  const loading = useAppSelector((state) => state.modal.loading);
  const { company, campaign, list } =
    useAppSelector((state) => state.form?.[form]?.values) || {};
  const dispatch = useAppDispatch();
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState<any>(null);
  const handleClose = () => {
    setDialogValue(null);
    toggleOpen(false);
  };

  const handleSubmit = async () => {
    if (!dialogValue || dialogValue == "") {
      ToasterService.showError("Value can not be empty");
      return;
    }
    dispatch(modalActions.setLoading(true));
    if (name == "company") {
      await CompanyService.addCompany({ fullName: dialogValue })
        .then(([response]) => {
          if (response?.data?.data?.company) {
            dispatch(
              change(form, "company", {
                label: response?.data?.data?.company?.fullName,
                value: response?.data?.data?.company?._id,
              })
            );
          }
          dispatch(modalActions.setLoading(false));
        })
        .catch(() => {
          dispatch(modalActions.setLoading(false));
        });
    }
    if (name == "campaign") {
      if (!company?.value) {
        ToasterService.showError("Company Should be selected");
        return;
      }
      await CampaignService.addCampaign(
        {
          name: dialogValue,
          company: company?.value,
        },
        false,
        true
      )
        .then(([response]) => {
          if (response?.data?.data?.campaign) {
            dispatch(
              change(form, "campaign", {
                label: response?.data?.data?.campaign?.name,
                value: response?.data?.data?.campaign?._id,
              })
            );
          }
          dispatch(modalActions.setLoading(false));
        })
        .catch(() => {
          dispatch(modalActions.setLoading(false));
        });
    }
    if (name == "list") {
      if (!company?.value && !campaign?.value) {
        ToasterService.showError("Company and campaign Should be selected");
        return;
      }
      await ListService.addList(
        {
          name: dialogValue,
          company: company?.value,
          campaign: campaign?.value,
        },
        false,
        true
      )
        .then(([response]) => {
          if (response?.data?.data?.list) {
            dispatch(
              change(form, "list", {
                label: response?.data?.data?.list?.name,
                value: response?.data?.data?.list?._id,
              })
            );
          }
          dispatch(modalActions.setLoading(false));
        })
        .catch(() => {
          dispatch(modalActions.setLoading(false));
        });
    }

    handleClose();
  };
  return (
    <React.Fragment>
      <Autocomplete
        {...rest}
        options={options || []}
        getOptionLabel={(option: any) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.label || "";
          }
          return option.label || "";
        }}
        isOptionEqualToValue={(option: any, value: any) =>
          option?.value === value?.value
        }
        onChange={(event, newValue: any) => {
          if (typeof newValue === "string" && rest?.addMore) {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue(newValue);
            });
          } else if (newValue && newValue.inputValue && rest?.addMore) {
            toggleOpen(true);
            setDialogValue(newValue.inputValue);
          } else {
            //@ts-ignore
            rest?.onChange(event, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "" && rest?.addMore) {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add New - "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderTags={(options: any[], getTagProps) =>
          options.map(({ label }: any, index: number) => (
            <Chip
              label={label}
              variant="filled"
              size={"small"}
              {...getTagProps({ index })}
              {...ChipProps}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <Input
            {...params}
            {...InputProps}
            inputProps={{
              autoComplete: "new-password",
              form: { autoComplete: "off" },
              ...params.inputProps,
              ...InputProps?.inputProps,
            }}
          />
        )}
      />

      <Dialog open={open} onClose={handleClose}>
        {loading && <CircleLoader />}

        <form>
          <DialogTitle>Add a {InputProps?.label}</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Confirm by click on submit to add new {InputProps?.label}
            </DialogContentText>
            <TextField
              autoFocus
              id="name"
              value={dialogValue}
              onChange={(event) => setDialogValue(event.target.value)}
              label="label"
              type="text"
              variant="outlined"
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="button" onClick={handleSubmit}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
