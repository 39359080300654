import { SetListRequestsPayload, ListRequestState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: ListRequestState = {
  count: 0,
  listRequests: [],
  listRequest: null,
  loading: false,
  listRequestOptions: [],
};

export const listRequestSlice = createSlice({
  name: "listRequest",
  initialState,
  reducers: {
    clear_search: (state) => {
      state.listRequests = [];
      state.listRequestOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setOptions: (state, action: PayloadAction<any[]>) => {
      state.listRequestOptions = action.payload;
    },
    updateListRequest: (state, action) => {
      const { id, listRequest } = action.payload;
      state.listRequests.every(({ _id }, i) => {
        if (id === _id) {
          state.listRequests[i] = listRequest;
          return false;
        }
        return true;
      });
    },
    deleteListRequestById: (state, action) => {
      const id = action.payload;
      state.listRequests.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.listRequests.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setListRequest: (state, action) => {
      state.listRequest = action.payload;
    },

    addListRequest: (state, action) => {
      state.listRequests.unshift(action.payload);
    },

    setListRequests: (state, action: PayloadAction<SetListRequestsPayload>) => {
      const { count, listRequests } = action.payload;
      state.count = count;

      let options: SelectOption[] = [];
      listRequests.forEach(({ _id, fullName, email }: any) =>
        options.push({ value: _id, label: fullName + " " + email })
      );
      state.listRequests = listRequests;
      state.listRequestOptions = options;
    },
    setSearchListRequests: (
      state,
      action: PayloadAction<SetListRequestsPayload>
    ) => {
      const { count, listRequests } = action.payload;
      state.count = count;

      let options: SelectOption[] = [];
      listRequests.forEach(({ _id, fullName, email }: any) =>
        options.push({ value: _id, label: fullName + " " + " - " + email })
      );
      state.listRequests = listRequests;
      state.listRequestOptions = options;
    },
  },
});

const listRequestReducer = listRequestSlice.reducer;

export const listRequestActions = listRequestSlice.actions;
export default listRequestReducer;
